import React from 'react';
import useLocale from "../Locale";
import { useSizeBay } from "./hooks/useSizeBay";
import { useEffect, useState } from "react";
import { SizeType } from "./Types";
import useAxios from "../hooks/useAxios";
import MadeToMeasure from "./MadeToMeasure";
import {IntlProvider } from "react-intl";

const axios = useAxios();

const sizeToIdMap = {
  "XS": 1,
  "S": 2,
  "M": 3,
  "L": 4,
  "XL": 5,
  "2XL": 6,
  "3XL": 7,
  "4XL": 8,
  "MTM": 101,
};



export default function SizeVerification(props) {

  const {
    orderId,
    token,
    sbPluginUsed,
    sbSize,
    size,
} = props;

  const [locale, messages] = useLocale(); 
  const [getSizeBaySize] = useSizeBay();
  const [sizebayReady, setSizebayReady] = useState(false);
  const [sizebayClosed, setSizebayClosed] = useState(false);
  const [currentSizebaySize, setCurrentSizebaySize] = useState(sbSize)
  const [pluginUsed, setPluginUsed] = useState(sbPluginUsed)
  const [mtmError, setMtmError] = useState(false);
  const [mtmSize, setMtmSize] = useState({
    id: 101,
    type: SizeType.MADE_TO_MEASURE,
    measurements: {},
  });


  const onMtmChange = (
    type,
    sizeId,
    measurements = {},
    shoeMeasurements = {}
  ) => {
    setMtmError(false);
    const newSize = {
      id: sizeId,
      type: type,
      measurements: measurements,
    };
    setMtmSize(newSize);
  };


  const checkAndUpdateSizeBaySize = () => {
      var sizeBaySize  = getSizeBaySize()
      if (sizeBaySize) {
        console.log("Size bay recommendation is " + sizeBaySize);
        updateSizebaySize(sizeBaySize);
        setCurrentSizebaySize(sizeBaySize);
        setPluginUsed(true);

        if (sizeBaySize == size) {
          console.log("Suggested size is same as ordered size");
          moveToOrderSuccess();
        }
      }
  }

  const updateSizebaySize = async (sizebay_size) => {
    const payload = {
      sizebay_size: sizebay_size,
    };

    try {
      const resp = await axios.post(`/order/update-regular-product-size/`+orderId, payload);
      console.log(resp);
      if (resp.data.success) {
        console.log("Size bay size has been updated successfully..");
      } else {
        showErrorMessage("Something went wrong. Please try again.");
      }
    } catch (e) {
      showErrorMessage("Something went wrong. Please try again.");
    } finally {
    }
  };


  const updateProductSize = async () => {
    var valid = validate();
    if (!valid) {
      return
    }

    const payload = getData();

    try {
      const resp = await axios.post(`/order/update-regular-product-size/`+orderId, payload);
      console.log(resp);
      if (resp.data.success) {
        console.log("Size has been updated successfully..");
        moveToOrderSuccess()
      } else {
        showErrorMessage("Something went wrong. Please try again.");
      }
    } catch (e) {
      showErrorMessage("Something went wrong. Please try again.");
    } finally {
    }
  };

  const moveToOrderSuccess = ()  => {
    window.location.href = "/success?token=" + token
  }

  const validate = () => {
    setMtmError(false);
    var sizeId = sizeToIdMap[currentSizebaySize]
    if (!sizeId) {
      showErrorMessage("Unable to update size. Size mapping is missing..");
      console.log("Unable to update size. Size mapping is missing..");
      return
    }

    switch (currentSizebaySize) {
      case "MTM":
        if (mtmSize.type == SizeType.LATER) {
          setMtmError(false);
          return true;
        }
        const measurementsValid =
          Object.keys(mtmSize.measurements).length >= 5 &&
          Object.values(mtmSize.measurements).every((v) => v > 0);
        setMtmError(!measurementsValid);
        return measurementsValid;
    }
    return true;
  };

  const getData = () => {
    var sizeId = sizeToIdMap[currentSizebaySize]

    var payload = {
      size: sizeId,
    }

    if (currentSizebaySize == "MTM") {
      if (mtmSize.type == SizeType.LATER) {
        payload["size"] = 100
      }
      payload["measurements"] = mtmSize.measurements
    }

    return payload
  };


  useEffect(() => {
    const checkSizebay = () => {
      if (window.Sizebay) {
        setSizebayReady(true);
        return true;
      }
      return false;
    };

    if (!checkSizebay()) {
      const intervalId = setInterval(() => {
        if (checkSizebay()) {
          clearInterval(intervalId);
        }
      }, 100);
      
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => { 
    if (sizebayReady && sizebayClosed) {
      checkAndUpdateSizeBaySize();
    }
  }, [sizebayClosed]);



  useEffect(() => { 
    if (sizebayReady) {
        const observer = new MutationObserver((mutationsList, observer) => {
        for(let mutation of mutationsList) {
          if (mutation.type == "attributes") {
            if (mutation.target.className == "vfr__container--backdrop szb-modal-open") {
                setSizebayClosed(false);
            } else if (mutation.target.className == "vfr__container--backdrop") {
                setSizebayClosed(true);
            }
          }
        }
      });

        // Start observing the document with the configured parameters
      observer.observe(document, { childList: true, subtree: true, attributes: true });
    
      return () => {
        // Clean up the observer on component unmount
        observer.disconnect();
      };
      
    }
  }, [sizebayReady]);

  return (
    <>
      {!pluginUsed ? (
        <>
            <div className="row text-center">
              <div className="col-sm-offset-2 col-sm-8 col-xs-12 mbl">
                <h4 className="mbm">Try Our Virtual Fitting Room</h4>
                <p>Thank you for choosing The Jacket Maker</p>
                <p>We believe that the right fit is key and noticed that you didn't use our specialized size tool, which helps you find the perfect fit since sizes can vary between brands and clothing types. As a small business. size returns can be a big challenge for us. Using the tool suggestions will save you from the disappointment of an ill-fitting product and the hassle of returns but also helps us reduce restocking efforts and potential product waste.</p>
                <p>We'd be very grateful if you could give it a try.</p>
              </div>
              <div className="col-sm-offset-2 col-sm-8 col-xs-12 mbl">
                <div id="sizebay-container"></div>
                <a href="#" className="form-btn phl">Verify your size</a>
              </div>
            </div>
        </>
      ) : (currentSizebaySize != size) ? (
        <>
          <div className="row pbn text-center">
            <div className="col-sm-offset-2 col-sm-8 col-xs-12 mbl">
              <h4 className="mbm">Thank You, your order has been placed</h4>
              <p>We have noticed that your suggested size is <b>{currentSizebaySize}</b> but you have placed <b>{size}</b> size. We would recommend to {currentSizebaySize}.</p>
            </div>
          </div>

          {!sbSize && (
            <div className="row pvn">
              <div className="col-sm-offset-2 col-sm-8 col-xs-12">
              </div>
            </div>
          )}

          {currentSizebaySize == "MTM" && (
            <IntlProvider locale={locale} messages={messages}>
              <div className="made-to-measure-checkout">
                <MadeToMeasure selectedSize={mtmSize} onChange={onMtmChange} error={mtmError} />
              </div>
            </IntlProvider>)
          }

          <div className="row pvn">
            <div className="col-sm-offset-2 col-sm-4 col-xs-6">
              <a href="#" onClick={updateProductSize} className="form-btn phl">Update size to recommended</a>
            </div>
            <div className="col-sm-4 col-xs-6 text-right">
              <a href="#" onClick={moveToOrderSuccess} className="form-btn phl">Continue with my selected size</a>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};