import React from "react";
import usePaypal from "./usePaypal";

const PaypalConfirmPayment = ({orderId}) => {
  const {confirmPayment, isLoading} = usePaypal();

  const handlePurchase = async () => {
    const response = await confirmPayment(orderId);
    console.log("response", response);
    if (response && response.link) {
      window.location.href = response.link;
    } else {
      alert("Something went wrong. Please try again. " + response.err);
    }
  };

  return (
    <button type="button" className="checkout-btn btn btn-style" onClick={handlePurchase}>
      {isLoading ? (<i className="fa fa-spinner fa-spin" />) : "Confirm Payment"}
    </button>
  );
};

export default PaypalConfirmPayment;
