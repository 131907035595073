import React, { useState } from "react";
import { useEffect } from "react";
import Select from 'react-select';
import { format } from 'react-string-format';

const LiveSearch = () => {

  const onFaqSelected = (e) => {
    window.addEventListener("hashchange", function(e){ 
      window.location.reload()
    });

    console.log(format("/help-center/qa/{0}", e.url));
    window.open(format("/help-center/qa/{0}", e.url), '_self');
  };

  const customStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: 'white',
      color: 'black',
    })
  }

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetch("/help-center/fetch-faqs")
      .then((res) => res.json())
      .then((data) => {
        setFaqs(data.faqs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const formatOptionLabel = ({ question, answer, category, sub_category }) => (
    <div style={{ display: "flex" }}>
      <div>{category + " > " + question}</div>
    </div>
  );

  const filterOption = (option, inputValue) => {
    let inputValues = inputValue.toLowerCase().split(' ');
    let matched = inputValues.find((val) => option.data.category_norm.includes(val) || option.data.question_norm.includes(val) || option.data.tags.includes(val));
    return typeof(matched) !== 'undefined';
  };

  return (
  <Select onChange={onFaqSelected} styles={customStyle} placeholder="SEARCH.." options={faqs} formatOptionLabel={formatOptionLabel} filterOption={filterOption} />
  );
}

export default LiveSearch;