import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { IntlProvider } from "react-intl";

const StripeAfterPay = forwardRef((props, ref) => {
  const stripe = useStripe();

  const { setProcessing } = props;
  const [afterpayDetails, setAfterpayDetails] = useState({ allow: false });
  const [clientSecret, setClientSecret] = useState("");

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(),
  }));

  useEffect(() => {
    document.addEventListener("show-payment-details-page", () => {
      fetchPaymentIntent();
      fetchAfterpayDetails();
    });
  }, []);

  useEffect(() => {
    props.onDetails(afterpayDetails);
  }, [afterpayDetails]);

  const fetchAfterpayDetails = () => {
    fetch("/stripe/afterpay", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setAfterpayDetails(data);
      });
  };

  const fetchPaymentIntent = () => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch("/stripe/intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pap_token: "abcd" }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.client_secret);
      });
  };

  const handleSubmit = async () => {
    setProcessing(true);
    const { error } = await stripe.confirmAfterpayClearpayPayment(
      clientSecret,
      {
        payment_method: {
          billing_details: afterpayDetails.details.billing_details,
        },
        return_url: window.location.origin + "/stripe/complete-afterpay",
      }
    );

    if (error) setError(error);
    else console.log("Success on afterpay");
    setProcessing(false);
  };

  return <React.Fragment></React.Fragment>;
});

export default StripeAfterPay;
