import React from "react";
import HelpIcon from "images/v2/icons/how.svg";
import Collapse from "@kunukn/react-collapse";
import useMeasurements from "./hooks/useMeasurements";
import { SizeType } from "./Types";
import { FormattedMessage } from "react-intl";

export default function MadeToMeasure({ selectedSize, onChange, error }) {

    const measurements = useMeasurements();

    const handleSizeLater = () => {
      console.log(selectedSize);
      if (selectedSize.type == SizeType.LATER) {
        onChange();
      } else {
        onChange(SizeType.LATER);
      }
    };

    const onMeasurementChange = (name, value) => {
      let newVal = value;
      if (value === "") {
        newVal = 0;
      }
      onChange(SizeType.MADE_TO_MEASURE, null, {
        ...selectedSize.measurements,
        [name]: parseFloat(newVal),
      });
    };

    return (
      <Collapse
        isOpen={
          selectedSize.type == SizeType.MADE_TO_MEASURE ||
          selectedSize.type == SizeType.LATER
        }
      >
        <div className="madetomeasure">
          <div className="row">
            {[0, 1].map((r) => (
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" key={r}>
                <form id="madetomeasure-form" className="form-inline">
                  {(r == 0
                    ? measurements.slice(0, 4)
                    : measurements.slice(4)
                  ).map((m, i) => (
                    <div className="form-group" key={i}>
                      <label className="measure-label">
                        <FormattedMessage id={"product.measurement." + m.name} defaultMessage={m.title} />
                        {m.required ? "*" : ""}
                      </label>
                      <input
                        value={selectedSize.measurements[m.name] || ''}
                        type="number"
                        step="any"
                        name={m.name}
                        className="form-control"
                        placeholder={m.unit}
                        onChange={(e) =>
                          onMeasurementChange(m.name, e.target.value)
                        }
                      />
                      <a href="#" data-toggle="modal" data-target={m.target}>
                        <img
                          width="25"
                          className="pull-right mtm"
                          src={HelpIcon}
                        />
                      </a>
                    </div>
                  ))}
                </form>
              </div>
            ))}

            {error && (
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="error text-danger mbl">
                  <FormattedMessage defaultMessage="Please provide all measurements" id="product.mtm.error" />
                </div>
              </div>
            )}

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 single-select">
              <form id="madetomeasure-later-form" className="form-inline">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="size-later"
                      checked={selectedSize.type == SizeType.LATER}
                      onChange={handleSizeLater}
                    />
                  </label>
                  <p><FormattedMessage defaultMessage="I am not sure, I'll provide measurements later on email" id="product.cart.size-later" /></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Collapse>
    );
  };
