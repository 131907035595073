import { useState, useEffect } from "react";

export const getCsrfToken = () => {
    const token = document.querySelector("meta[name=csrf-token]").content;
    return token;
};

export const getCommonHeaders = () => {
    return {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken(),
    };
};

export const usePersistedState = (key, defaultValue) => {
    const getState = () => {
        const data = localStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        return defaultValue;
    }

    const setState = (value) => {
        localStorage.setItem(key, JSON.stringify(value));
    }

    return [getState, setState];
}
