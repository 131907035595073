import { useEffect, useState } from "react";

export const useSizeBay = () => {
  const [size, setSize] = useState(null);

  const fetchSize = () => {
    var sizeBaySize = null;
    if (
      window.hasOwnProperty("SizebayParams") &&
      window.SizebayParams.hasOwnProperty("recommendation") &&
      window.SizebayParams.recommendation.length > 0
    ) {
      sizeBaySize = window.SizebayParams.recommendation[0].size;
    }
    // TODO: Confirm with Osama
    // if (typeof window.sb !== "undefined") {
    //   sb.product_added_to_cart({});
    // }
    return sizeBaySize;
  };


  const getSizeBaySize = () => {
    if (!size) {
      return fetchSize();
    }
    return size;
  }


  useEffect(() => {
    // Event listener for the new event
    const handleSizeBayEvent = (event) => {
      if (event.data == "szb-no-recommendation-in-app") {
        setSize("MTM");
      } else if (event.data == "szb-recommendation-done-in-app") {
        var sizeBaySize = fetchSize();
        if (sizeBaySize) {
          setSize(sizeBaySize);
        }
      }
    };


    window.addEventListener('message', handleSizeBayEvent);

    return () => {
      window.removeEventListener('message', handleSizeBayEvent);
    };
  }, []);


  return [getSizeBaySize];
};
