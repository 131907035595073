const regularSizes = {
  US: [
    7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 12.5, 13.0,
    13.5, 14.0, 14.5,
  ],
  Euro: [
    37.0, 37.5, 38.0, 38.5, 39.0, 39.5, 40.0, 40.5, 41.0, 41.5, 42.0, 42.5,
    43.0, 43.5, 44.0, 44.5, 45.0, 45.5, 46.0, 46.5, 47.0, 47.5, 48.0,
  ],
  UK: [
    6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 12.5,
    13.0, 13.5,
  ],
};

const regularSizeTypes = ["US", "EU", "UK"];

const measurementTypes = ["cm", "inches"];

const footWidthTypes = ["Narrow", "Standard", "Wide"];

const uniqueFeaturesTypes = ["None", "High Arch", "Flat Feet", "Bunions"];

export default function useShoeMeasurements() {
  return [regularSizeTypes, regularSizes, measurementTypes, footWidthTypes, uniqueFeaturesTypes];
}
