import React, { useState, useRef } from "react";
import CCImage from "images/cc-image-checkout.jpg";
import PayPalLogo from "images/v2/payment/paypal-logo.png";
import Popup from "images/v2/icons/popup.svg";
import Back from "images/v2/icons/left-arrow.png";
import AmazonPay from "images/v2/payment/amazonpay.png";
import afterPay from "images/v2/payment/ap-logo.png";

import StripePayment from "./StripePayment";
import StripeAfterPay from "./StripeAfterPay";
import usePaypal from "./usePaypal";
import useLocale from "../Locale";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentOptions = ({ paymentRequired, region, stripeKey }) => {
  const [selectedPayment, setSelectedPayment] = useState("creditCard");
  const stripePaymentRef = useRef();
  const stripeAfterpayRef = useRef();
  const [processing, setProcessing] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [afterpayDetails, setAfterpayDetails] = useState({allow: false});

  const [locale, messages] = useLocale();

  const { createOrder, isLoading: paypalLoading } = usePaypal();

  const handlePayNow = async () => {
    setErrorText("");
    if (paymentRequired) {
      switch (selectedPayment) {
        case "creditCard":
          stripePaymentRef.current.submit();
          break;
        case "afterpay":
          stripeAfterpayRef.current.submit();
          break;
        case "paypal":
          const order = await createOrder();
          console.log("order", order);
          if (order && order.details) {
            let redirectUrl = "";
            // get the link with approval_url from links
            order.details.links.forEach((link) => {
              if (link.rel === "approve" || link.rel === "payer-action") {
                // redirect to the link
                redirectUrl = link.href;
              }
            });

            if (redirectUrl) {
              window.location.href = redirectUrl;
            } else {
              setErrorText("Failed to create order, " + order.details.message);
            }
          }
          break;
      }
  } else {
    confirmOrder();
  }
  };

  const handleSelection = (paymentType) => {
    setSelectedPayment(paymentType);
    console.log(paymentType);
  };

  const isAfterpayAllowed = () => {
    if (region != "global") {
      return false;
    }

    if (!afterpayDetails || !afterpayDetails.allow) {
      return false;
    }

    return true;
  }

  return (
    <>
      <Elements options={{ locale: locale }} stripe={stripePromise}>
      <div className="pth pbs mbn">
          <strong>Payment</strong>
          <p className="mln">All transactions are secure and encrypted.</p>
      </div>
        {paymentRequired && (
        <div className="payment-accordion" id="accordionExample">
          <div className="card">
            <div className="card-header creditcart-header" id="headingOne">
              <h2 className="mb-0">
                <input
                  type="radio"
                  name="payment"
                  value="creditCard"
                  checked={selectedPayment === "creditCard"}
                  onChange={() => handleSelection("creditCard")}
                />
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => setSelectedPayment("creditCard")}
                >
                  Credit Card
                </button>
                <span>
                <img
                  src={CCImage}
                  style={{  }}
                  className="mrm cc-logo"
                />
                and more...</span>
              </h2>
            </div>

            <div
              id="creditcard"
              className={
                selectedPayment === "creditCard" ? "collapse show" : "collapse"
              }
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                {paymentRequired && (
                  <StripePayment
                    ref={stripePaymentRef}
                    setProcessing={setProcessing}
                    publishKey={stripeKey}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <input
                  type="radio"
                  name="payment"
                  value="paypal"
                  checked={selectedPayment === "paypal"}
                  onChange={() => handleSelection("paypal")}
                />

                <button
                  className="btn btn-link collapsed"
                  type="button"
                  onClick={() => setSelectedPayment("paypal")}
                >
                  <img
                    src={PayPalLogo}
                    style={{ width: 70 }}
                    className="mtl mbl cc-logo"
                  />
                </button>
              </h2>
            </div>
            <div
              id="paypal-tab"
              className={
                selectedPayment === "paypal" ? "collapse show" : "collapse"
              }
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body checkout-popup pth pbh">
                <img
                  src={Popup}
                  style={{ width: 100 }}
                  className="center-block"
                />
                <p className="center-block mtl">
                  After clicking "PAY NOW", you will be redirected to PayPal to
                  complete your purchase securely.
                </p>
              </div>
            </div>
          </div>

          <div className={isAfterpayAllowed() ? "card" : "card hidden"}>
            <div className="card-header" id="stripe-afterpay">
              <h2 className="mb-0">
                <input
                  type="radio"
                  name="payment"
                  value="afterpay"
                  checked={selectedPayment === "afterpay"}
                  onChange={() => handleSelection("afterpay")}
                />
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  onClick={() => setSelectedPayment("afterpay")}
                >
                  <img
                    src={afterPay}
                    style={{ width: 70 }}
                    className="mtl mbl cc-logo"
                  />

                  <StripeAfterPay
                    ref={stripeAfterpayRef}
                    setProcessing={setProcessing}
                    onDetails={setAfterpayDetails}
                  />
                </button>
              </h2>
            </div>
            <div
              id="afterPay"
              className={
                selectedPayment === "afterpay" ? "collapse show" : "collapse"
              }
              aria-labelledby="stripe-afterpay"
              data-parent="#accordionExample"
            >
              <div className="card-body checkout-popup pth pbh">
                <img
                  src={Popup}
                  style={{ width: 100 }}
                  className="center-block"
                />
                <p className="center-block mtl">
                  You will be required to provide details to AfterPay after
                  clicking "PAY NOW".
                </p>
              </div>
            </div>
          </div>

          <div className="card hidden">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <input
                  type="radio"
                  name="payment"
                  value="amazonPay"
                  checked={selectedPayment === "amazonPay"}
                  onChange={() => handleSelection("amazonPay")}
                />
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  onClick={() => setSelectedPayment("amazonPay")}
                >
                  <img
                    src={AmazonPay}
                    style={{ width: 70 }}
                    className="mtl mbl cc-logo"
                  />
                </button>
              </h2>
            </div>
            <div
              id="amazon"
              className={
                selectedPayment === "amazonPay" ? "collapse show" : "collapse"
              }
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body checkout-popup pth pbh">
                <img
                  src={Popup}
                  style={{ width: 100 }}
                  className="center-block"
                />
                <p className="center-block mtl">
                  You will be asked to login with Amazon.
                </p>
              </div>
            </div>
          </div>
        </div>)}

      {errorText && <div className="pal checkout-error red">{errorText}</div>}

        <div className="col-md-6 col-sm-6 col-lg-6 col-sm-6 mtl hidden-xs">
            <a data-back-btn>
            <img
            src={Back}
            className="pull-left add-more"
            />

            <h5 className="back-btn email-edit-btn">Back</h5>
            </a>
        </div>

        <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12 mtl text-right phn">
          <button
            className="btn btn-style"
            type="button"
            onClick={handlePayNow}
            disabled={processing || paypalLoading}
          >

            {processing || paypalLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              paymentRequired ? "PAY NOW" : "CREATE"
            )}
          </button>
        </div>

        <div className="col-xs-offset-4 col-xs-6 mvl hidden-lg hidden-md hidden-sm">
            <a data-back-btn>
            <img
            src={Back}
            className="pull-left add-more"
            />

            <h5 className="back-btn email-edit-btn">Back</h5>
            </a>
        </div>
      </Elements>
    </>
  );
};

const confirmOrder = async () => {
  try {
    const res =  await fetch("/order/confirm-v2");
    const data = await res.json();
    window.location.href =  data.link;
  } catch (error) {
    console.error("Error confirming order:", error);
    return { err: error };
  }
};


export default PaymentOptions;
