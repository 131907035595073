import { useState } from "react";

const usePaypal = () => {
  const [isLoading, setLoading] = useState(false);

  const createOrder = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/paypal-payment/v2/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [],
          is_express: false,
        }),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error creating PayPal order:", error);
      return { err: error };
    } finally {
      setLoading(false);
    }
  };

  const confirmPayment = async (orderId) => {
    setLoading(true);
    try {
      const response = await fetch("/api/paypal-payment/v2/execute", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_id: orderId,
          is_express: false,
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error confirming PayPal payment:", error);
      return { err: error };
    } finally {
      setLoading(false);
    }
  };

  return {createOrder, confirmPayment, isLoading};
};

export default usePaypal;
