const measurements = [
  {
    name: "chest",
    title: "Chest",
    required: true,
    unit: "in / cm",
    target: ".chest",
  },
  {
    name: "waist",
    title: "Natural Waist",
    required: true,
    unit: "in / cm",
    target: ".NWaist",
  },
  {
    name: "lowerWaist",
    title: "Lower Waist",
    required: true,
    unit: "in / cm",
    target: ".LWaist",
  },
  {
    name: "hips",
    title: "Hips",
    required: false,
    unit: "in / cm",
    target: ".Hips",
  },
  {
    name: "height",
    title: "Height",
    required: true,
    unit: "in / cm",
    target: ".Height",
  },
  {
    name: "weight",
    title: "Weight",
    required: true,
    unit: "lbs",
    target: ".Weight",
  },
  {
    name: "shoulder",
    title: "Shoulder",
    required: false,
    unit: "in / cm",
    target: ".Shoulder",
  },
  {
    name: "sleeves",
    title: "Sleeves",
    required: false,
    unit: "in / cm",
    target: ".Sleeves",
  },
];

export default function useMeasurements() {
  return measurements;
};
