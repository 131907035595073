import React, { useState } from 'react';
import Modal from 'react-modal';
import BlackLogo from "images/v2/logo-black.svg";
import EviternitySizeImage from "images/v2/ev/eviternity-size-image.jpg"
import SizeRecommendationSuccessImage from "images/v2/ev/Wholecut-Midnight.jpg"

Modal.setAppElement('.row'); 

const ShoeSizeRecommendationTool = () => {

  const regions = ["US", "EU", "UK", "AU"];
  const sneakerSizes = {
    US: [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5],
    UK: [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5],
    EU: [40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 47.5],
    AU: [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5]
  };

  const formalSizes = {
    US: [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5],
    UK: [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5],
    EU: [40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 47.5],
    AU: [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5]
  };
  const widths = ['Standard', 'Narrow', 'Wide', 'I don\'t know']


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('US');
  const [regionSneakerSizes, setRegionSneakerSizes] = useState(sneakerSizes[selectedRegion]);
  const [regionFormalSizes, setRegionFormalSizes] = useState(formalSizes[selectedRegion]);


  const [selectedSneakerSize, setSelectedSneakerSize] = useState(0);
  const [selectedFormalSize, setSelectedFormalSize] = useState(0);
  const [selectedOtherSneakrerSize, setSelectedOtherSneakrerSize] = useState(0);
  const [selectedOtherSneakrerSizeDiff, setSelectedOtherSneakrerSizeDiff] = useState(0);  
  const [selectedWidth, setSelectedWidth] = useState('Standard');
  const [step, setStep] = useState(1);

  

  const handleRegionChange = (event) => {
    var selectedRegion = event.target.value
    console.log("region selected  " + selectedRegion);
    setSelectedRegion(selectedRegion);
    setRegionSneakerSizes(sneakerSizes[selectedRegion]);
    setRegionFormalSizes(formalSizes[selectedRegion]);
  };

  const handleSneakerSizeChange = (event) => {
    console.log("sneaker size  selected  " + event.target.value);
    var sizeSelected = parseFloat(event.target.value);
    setSelectedSneakerSize(sizeSelected);
    setSelectedOtherSneakrerSize(sizeSelected);
  };

  const hanldeSneakerSizeDontKnow = (event) => {
    console.log("sneaker size na  " + event.target.value);
    setSelectedSneakerSize(-1);
  }

  const handleOtherSneakerSizeChange = (event) => {
    const sizeDiff = parseFloat(event.target.value);
    console.log(sizeDiff);
    setSelectedOtherSneakrerSizeDiff(sizeDiff);
    setSelectedOtherSneakrerSize(selectedSneakerSize + sizeDiff);
  };

  const handleFormalSizeChange = (event) => {
    console.log("formal size  selected  " + event.target.value);
    setSelectedFormalSize(parseFloat(event.target.value));
  };

  const hanldeFormalSizeDontKnow = (event) => {
    console.log("formal size na  " + event.target.value);
    setSelectedFormalSize(-1);
  }

  const handleWidthChange = (event) => {
    console.log("width selected" + event.target.value);
    setSelectedWidth(event.target.value);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleNext = () => {
    setStep(step => step + 1);
    console.log("step is " + step);
  };

  const handleBack = () => {
    setStep(step => step - 1);
  };


  const calculateRecommendation = () => {
    if (selectedSneakerSize === -1) {
      console.log("resetting");
    }

    console.log("Sneaker Size " + selectedSneakerSize);
    console.log("Sneaker Other Size " + selectedOtherSneakrerSize);
    console.log("Sneaker Formal Size " + selectedFormalSize);
    console.log("Width is " + selectedWidth)
    const noRecommendation = 0;
    if (selectedSneakerSize === selectedOtherSneakrerSize) {
      //a=b and c=NA -> (a-0.5)
      if (selectedFormalSize <= 0) return {recommendedSize: selectedSneakerSize - 0.5, recommendedWidth: selectedWidth};
      //a=b and c=a or b -> c
      if (selectedFormalSize === selectedOtherSneakrerSize) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b and c=b-0.5 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize - 0.5) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b and c=b-1 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize - 1) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b and c=b+0.5
      if (selectedFormalSize === selectedOtherSneakrerSize + 0.5) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b and c=b+1.5 or greater
      if (selectedFormalSize >= selectedOtherSneakrerSize + 1.5) return {recommendedSize:noRecommendation, recommendedWidth:selectedWidth};
      //a=b and c=b-1.5 or greater
      if (selectedFormalSize >= selectedOtherSneakrerSize - 1.5) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
    }

    if (selectedSneakerSize === selectedOtherSneakrerSize + 0.5) {
      //a=b+0.5 and c= NA -> b
      if (selectedFormalSize <= 0) return {recommendedSize:selectedOtherSneakrerSize, recommendedWidth:selectedWidth};
      //a=b+0.5 and c=b -> c
      if (selectedFormalSize === selectedOtherSneakrerSize) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b+0.5 and c=b-0.5 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize - 0.5) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b+0.5 and c=b-1.0 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize - 1.0) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b+0.5 and c=b+0.5 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize + 0.5) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
      //a=b+0.5 and c=b-1.5 or greater
      if (selectedFormalSize >= selectedOtherSneakrerSize - 1.5) return {recommendedSize:noRecommendation, recommendedWidth:selectedWidth};
    }
    

    if (selectedSneakerSize === selectedOtherSneakrerSize - 0.5) {
      //a=b-0.5 and c=NA -> a
      if (selectedFormalSize <= 0) return {recommendedSize:selectedSneakerSize, recommendedWidth:selectedWidth};
      //a=b-0.5 and c=b -> b
      if (selectedFormalSize === selectedOtherSneakrerSize) return {recommendedSize:selectedOtherSneakrerSize, recommendedWidth:selectedWidth};
      //a=b-0.5 and c=b-0.5 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize - 0.5) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
    }

    if (selectedSneakerSize === selectedOtherSneakrerSize - 1.0) {
      //a=b-1 and c=a -> c
      if (selectedFormalSize === selectedSneakerSize) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
    }


    if (selectedSneakerSize === selectedOtherSneakrerSize + 1.0) {
      //a=b+1 and c=b-1 -> c
      if (selectedFormalSize === selectedOtherSneakrerSize - 1.0) return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
    }

    if (selectedSneakerSize < 1 && selectedOtherSneakrerSize < 1 && selectedFormalSize > 0 ) {
      //a=na, b=na, c=x. Result=x.
      return {recommendedSize:selectedFormalSize, recommendedWidth:selectedWidth};
    }

    return {recommendedSize:noRecommendation, recommendedWidth:selectedWidth};
  };

  const suggest = () => {
    const {recommendedSize, recommendedWidth} = calculateRecommendation()
    if (recommendedSize === 0) {
      return "Please contact our cs team"
    } else {
      if (recommendedWidth != 'I don\'t know') {
        return "We recommend you a " + recommendedWidth + " " + recommendedSize + " size."
      } else {
        return "We recommend you " + recommendedSize + " size."
      }
    }
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mtl">
      <button className="customize-btn btn-customize-product btn btn-default pull-right" onClick={handleOpenPopup}>Open Size Recommendation Tool
      </button>
      <Modal
      
        style={
          {
            content: {
              margin: '0px'
            },
          }
        } 
        isOpen={isPopupOpen} 
        onClose={handleClosePopup} 
        onRequestClose={handleClosePopup}
        shouldCloseOnOverlayClick={true}
        closeButton={<button onClick={handleClosePopup}>Close</button>}
        >
        <div className="container-fluid eviternity-size-tool">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <img src={BlackLogo} width="80" />
            </div>
          </div> 
          <div className={"row border" + (step == 4 ? " hidden": "" )} >
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 en-size-image hidden-xs">
              <img src={EviternitySizeImage} />
            </div>
            {step == 1 && (
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mvh pah size-tool-content">
                <h4>Welcome to the Virtual Fitting Room</h4>
                <p>Answer a few simple questions and we will suggest the best size for you.</p>
                <div className="mth">
                    <h5>Select your region</h5>
                </div>
                <div className="row eviternity-region">
                    {regions.map(region => (
                      <div key={region} className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <input id={region} type="radio" name="c-region" value={region} checked={selectedRegion === region} onChange={handleRegionChange}/>
                          <label htmlFor={region}>{region}</label>
                      </div>
                    ))}

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mth">
                    <button className="cart-btn btn btn-default" onClick={handleNext} disabled={!selectedRegion}>Next</button>
                </div>    
                </div>
                
                
              </div>
            )}
            {step == 2 && (
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mvh pah size-tool-content">
                <div className="mth">
                    <h5>What is your sneaker size?</h5>
                </div>
                <div className="eviternity-size eviternity-region mtl">
                  {regionSneakerSizes.map(sneakerSize => (
                      <div key={sneakerSize} >
                        <input
                            id={"sneaker-size-" + sneakerSize}
                            type="radio"
                            name="c-sneakrer-size"
                            value={sneakerSize}
                            onChange={handleSneakerSizeChange}
                        />
                        <label htmlFor={"sneaker-size-" + sneakerSize}>{sneakerSize}</label>
                      </div>
                  ))}
                </div>
                {selectedSneakerSize && selectedSneakerSize != -1 ? (
                <div className="mth">
                    <h5 className="mbm">Do you ever wear another size in sneakers?</h5>
                            <div>      
                              <label htmlFor="other-sneaker-size-0.5">
                              <input id="other-sneaker-size-0.5" type="radio" name="c-sneaker-size-other" value="-0.5" checked={selectedOtherSneakrerSizeDiff == -0.5} onChange={handleOtherSneakerSizeChange}/>  
                              Occassionally, I wear size {selectedSneakerSize-0.5}
                              </label>
                            </div>
                            <div>
                              <label htmlFor="other-sneaker-size0">
                                <input id="other-sneaker-size0" type="radio" name="c-sneaker-size-other" value="0" checked={selectedOtherSneakrerSizeDiff == 0} onChange={handleOtherSneakerSizeChange} />
                                I always wear size {selectedSneakerSize}
                              </label>
                            </div>
                            <div>
                              <label htmlFor="other-sneaker-size0.5">
                              <input id="other-sneaker-size0.5" type="radio" name="c-sneaker-size-other" value="0.5" checked={selectedOtherSneakrerSizeDiff == 0.5} onChange={handleOtherSneakerSizeChange} />
                              Occassionally, I wear size {selectedSneakerSize+0.5}
                              </label>
                            </div>
                </div>) : null}

                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mvl phn">
                    {(!selectedSneakerSize || selectedSneakerSize === -1) && (  
                      <div key="sneaker-size-na">
                      <input
                          id={"sneaker-size-na"}
                          type="radio"
                          name="sneaker-size-na"
                          value="sneaker-size-na"
                          checked={selectedSneakerSize === -1}
                          onChange={hanldeSneakerSizeDontKnow}
                      />
                      <label htmlFor="sneaker-size-na">I don't know my sneaker size</label>
                    </div>
                    )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mth">
                  <button className="customize-btn btn-customize-product btn btn-default pull-left" onClick={handleBack}>Back</button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mth">
                  <button className="cart-btn btn btn-default pull-right" onClick={handleNext} disabled={!selectedSneakerSize}>Next</button>
                </div>
              </div>
            )}

            {step == 3 && (
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 mvh pah size-tool-content">
                <div className="mth">
                  <h5 className="mbm">What is your formal size?</h5>
                </div>
                <div className="eviternity-size eviternity-region mtl">
                  {regionFormalSizes.map(formalSize => (
                    <div>
                        <input
                          id={"formal-size-"+formalSize}
                          name="c-formal-size"
                          type="radio"
                          value={formalSize}
                          onChange={handleFormalSizeChange}
                        />
                        <label htmlFor={"formal-size-"+formalSize}> {formalSize}</label>
                    </div>
                  ))}
                </div>
                <div className="mtl">
                    {(!selectedFormalSize || selectedFormalSize === -1) && (  
                      <div key="formal-size-na">
                      <input
                          id={"formal-size-na"}
                          type="radio"
                          name="formal-size-na"
                          value="formal-size-na"
                          checked={selectedFormalSize === -1}
                          onChange={hanldeFormalSizeDontKnow}
                      />
                      <label htmlFor="formal-size-na">I don't know my formal shoe size</label>
                    </div>
                    )}
                </div>
                <div className="mth">
                  <h5 className="mbm">Which width do you normally wear?</h5>
                  {widths.map((width, index) => (
                    <div>
                        <input
                          id={"width-"+width}
                          name="c-width"
                          type="radio"
                          value={width}
                          checked={selectedWidth === width} 
                          onChange={handleWidthChange}
                        />
                        <label htmlFor={"width-"+width}>{width}</label>
                    </div>
                  ))}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mth">
                  <button className="customize-btn btn-customize-product btn btn-default pull-left" onClick={handleBack}>Back</button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mth">
                  <button className="cart-btn btn btn-default pull-right" disabled={!selectedFormalSize || !selectedWidth} onClick={handleNext}>Next</button>
                </div>
              </div>
            )}
            {step == 5 && (
              <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 pah size-tool-content">
                <div className="row eviternity-region input-form">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mbl">
                            <h4>We couldn't find a size for you.</h4>
                            <p>Please share your details and our Customer Support Team will reach out to you for guidance on your size.</p>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mbl">
                            <label>
                                Name
                            </label>
                            <input type="name" data-field-update="" name="name" value="Enter Name" placeholder="Name" />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mbl">
                            <label>
                                Email
                            </label>
                            <input type="email" data-field-update="" name="email" value="Enter email address" placeholder="Email" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mtl">
                            <button className="cart-btn btn btn-default pull-right">Submit</button>
                        </div>
                    </div>
              </div>
            )}
          </div>


          {step == 4 && (
              <div className="row border">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center pbh">
                  <div className="hidden-xs">
                    <img src={SizeRecommendationSuccessImage} width="400" />
                  </div>

                  <div className="hidden-lg hidden-md hidden-sm">
                    <img src={SizeRecommendationSuccessImage} width="200"/>
                  </div>
              
                  <h4 className="mbm">{suggest()}</h4>
                  <p>Still confused? Reach out to us from <a onClick={handleNext}>here</a></p>
                </div>
              </div>
            )}
        </div>
      </Modal>
    </div>
  );
};

export default ShoeSizeRecommendationTool;