import React, { useState } from 'react';

const RecommendationRating = () => {
  const ratingLimit = Array.from({ length: 10 }, (_, index) => index + 1);
  const [activeLabel, setActiveLabel] = useState('3');

  const handleRadioChange = (event) => {
    setActiveLabel(event.target.value);
  };

  return (
    <div className="wr01 text-left mbh">
      <h4>3. How likely are you to recommend our company to a friend or family member?</h4>
      <p>(10 = Most likely)</p>
      <div className="number-ratting">
        {ratingLimit.map((number) => (
          <div key={number}>
            <label htmlFor={`nr-${number}`} className={activeLabel === number.toString() ? 'active' : ''}>
              {number}
            </label>
            <input
              type="radio"
              id={`nr-${number}`}
              name="rec-r"
              value={number.toString()}
              checked={activeLabel === number.toString()}
              onClick={handleRadioChange}
              onChange={handleRadioChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendationRating;