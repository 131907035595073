import React from "react";
import useLocale from "../Locale";
import { FormattedMessage, IntlProvider } from "react-intl";
import { useState } from "react";
import axios from "axios";

const WriteReview = () => {
  const [locale, messages] = useLocale();

  const [ratingError, setRatingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textError, setTextError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [review, setReview] = useState({
    rating: 5,
    email: "",
    text: "",
  });

  const handleSubmit = async () => {
    if (review.rating === 0) {
      setRatingError(true);
      return
    }

    if (review.text === "") {
      setTextError(true);
      return
    }

    setLoading(true);
    try {
      const resp = await axios.post("/reviews/submit", review);
      if (resp.data.success) {
        setShowSuccess(true);
      } else {
        console.log(resp.data);
        alert(resp.data.message);
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <div className="row pvh">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-sm-offset-3 mbh">
          <h3 className="mvs"><FormattedMessage id="review.write.heading" defaultMessage={"Your Feedback is Valued!"} /></h3>
          <p className="pvs">
            <FormattedMessage id="review.write.sub_heading" defaultMessage={"Thank you for your purchase and we are grateful you are here to share your thoughts about how things look and feel. Your feedback helps us in improvement and other online shoppers in making an informed decision."} />
          </p>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-sm-offset-3">
          <div>
            <div className="write-review-open">
              <div className="wr01">
                <h4><FormattedMessage id="review.write.rate" defaultMessage={"Rate"} /></h4>
                <div className="pull-left mtm">
                  <input
                    required
                    id="input-1"
                    name="rating"
                    className="rating rating-loading"
                    data-min="0"
                    data-max="5"
                    data-step="1"
                    data-size="xs"
                    disabled={showSuccess}
                    value={review.rating}
                    onChange={(e) => {
                      setReview({ ...review, rating: e.target.value });
                      setRatingError(false);
                    }}
                  />
                  <div className="rating-span">
                    <span className="excellent text-uppercase"><FormattedMessage id="review.write.excellent" defaultMessage={"Excellent"} /></span>
                    <span className="poor text-uppercase"><FormattedMessage id="review.write.poor" defaultMessage={"Poor"} /></span>
                  </div>
                  {ratingError && (
                    <p className="review-rating-error red">
                      <FormattedMessage id="review.write.error.rating" defaultMessage={"Rating must be selected."} />
                    </p>
                  )}
                </div>

                <div className="form-group mtl">
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Email"
                    disabled={showSuccess}
                    value={review.email}
                    className="form-control mvm"
                    onChange={(e) =>
                      setReview({ ...review, email: e.target.value })
                    }
                  />

                  <textarea
                    name="text"
                    required
                    className="form-control mbm"
                    rows="3"
                    disabled={showSuccess}
                    placeholder="Feedback"
                    value={review.text}
                    onChange={(e) => {
                      setReview({ ...review, text: e.target.value });
                      setTextError(false);
                    }}
                  />
                  {textError && (
                    <p className="review-text-error red">
                      <FormattedMessage id="review.write.error.text" defaultMessage={"Message should be more than 10 characters."} />
                    </p>
                  )}
                  <div>

                  <div className="customCheckbox mvl">
                      <input type="checkbox" name="allow_review" className="mrm" checked />
                      <span>I allow the review to be published on third Party Review Platforms.</span>
                  </div>

                    <button
                      disabled={showSuccess}
                      onClick={handleSubmit}
                      className="review-btn cart-btn btn btn-default"
                    >
                      <FormattedMessage id="review.write.submit" defaultMessage={"post"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {showSuccess && (
              <div className="wr03 text-center mth">
                <h4 style={{ marginTop: 100, marginBottom: 100 }}>
                  <FormattedMessage id="review.write.success" defaultMessage={"Thanks for posting a review!"} />
                </h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

export default WriteReview;
