import React from "react";
import useLocale from "../Locale";
import MadeToMeasure from "./MadeToMeasure";
import { FormattedMessage, IntlProvider } from "react-intl";
import { SizeType } from "./Types";

const MadeToMeasureCheckout = () => {
	const [locale, messages] = useLocale();
	const selectedSize = {
		measurements: {},
		type: SizeType.MADE_TO_MEASURE,
	};
	return (
		<IntlProvider locale={locale} messages={messages}>
			<div className="made-to-measure-checkout">
				<MadeToMeasure selectedSize={selectedSize} onChange={() => console.log(arguments)}  />
			</div>
		</IntlProvider>
	);
}

export default MadeToMeasureCheckout;