import React, { useEffect, useState, useRef } from "react";
import CartIcon from "images/v2/icons/cartb.svg";
import PurchaseIcon from "images/v2/icons/listing-purchase.svg";
import shippingCheck from "images/v2/icons/shipping-check.svg";
import useAxios from "../hooks/useAxios";
import useLocale from "../Locale";
import useRegion from "../Region";
import { FormattedMessage, IntlProvider } from "react-intl";

const axios = useAxios();

const EmptyCart = ({ onClose }) => (
  <div className="added-items-toggle">
    <button onClick={() => onClose(false)} className="cart-close"></button>
    <img src={PurchaseIcon} />
    <h3>
      <FormattedMessage id="cart.empty" defaultMessage="Your cart is empty!" />
    </h3>
  </div>
);

const CartLoading = () => (
  <div className="added-items-toggle">
    <h3>
      <i className="fa fa-spinner fa-spin"></i> &nbsp;
      <FormattedMessage id="cart.loading" defaultMessage="Loading ..." />
    </h3>
  </div>
);

const SuggestedProductItem = ({ product, onAddToCart }) => {
  const [selectedSize, setSelectedSize] = useState("");

  useEffect(() => {
    if (product.sizes.length > 0) {
      setSelectedSize(product.sizes[0].id);
    }
  }, [product]);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  return (
    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <a href={product.url}>
        <p className="mbn text-center">
          <img src={product.image} alt={product.model} />
          {product.model}
        </p>
      </a>
      <strong className="mtm">{product.price}</strong>
      {product.original_price !== null && (
        <strong className="discount-price">{product.original_price}</strong>
      )}
      {product.sizes.length > 0 && (
        <select
          className="mvm"
          name="product_size"
          value={selectedSize}
          onChange={handleSizeChange}
        >
          {product.sizes.map((size, i) => (
            <option key={i} value={size.id}>
              {size.name}
            </option>
          ))}
        </select>
      )}
      <button
        type="submit"
        onClick={() => onAddToCart(product.product_id, selectedSize)}
        className="cart-btn-link mbl"
      >
        Add to cart
      </button>
    </div>
  );
};

const SuggestedProducts = ({ suggestions, onAddToCart, id }) => {
  if (suggestions.length == 0) {
    return null;
  }

  const suggestionGrouped = suggestions.reduce((acc, curr) => {
    // in groups of size 2
    if (acc.length == 0 || acc[acc.length - 1].length == 2) {
      acc.push([]);
    }
    acc[acc.length - 1].push(curr);
    return acc;
  }, []);

  return (
    <div className="row toggle-checkout mln ptm pbh mbh text-center">
      <div className="col-lg-12 col-md-12 col-sm-12 ymal pbl">
        <strong className="no-border text-uppercase text-center mbl">
          you may also like
        </strong>

        <div id={"carousel-" + id} className="carousel slide">
          <div className="carousel-inner" role="listbox">
            {suggestionGrouped.map((sg, ig) => {
              return (
                <div
                  key={ig}
                  className={ig == 0 ? "row pvn item active" : "row pvn item"}
                >
                  {sg.map((s, i) => {
                    return (
                      <SuggestedProductItem
                        key={i}
                        product={s}
                        onAddToCart={onAddToCart}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
          <a
            className="left carousel-control"
            href={"#carousel-" + id}
            role="button"
            data-slide="prev"
          >
            <span
              className="glyphicon glyphicon-menu-left"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href={"#carousel-" + id}
            role="button"
            data-slide="next"
          >
            <span
              className="glyphicon glyphicon-menu-right"
              aria-hidden="true"
            ></span>
            <span className="sr-only"> Next </span>
          </a>
        </div>
      </div>
    </div>
  );
};

const CartWithProducts = ({
  order,
  onQuantityUpdate,
  suggestions,
  onAddToCart,
  open,
  id,
  onClose,
  onAddCoupon,
  couponError,
}) => {
  const classes = ["added-items-toggle"];

  const [coupon, setCoupon] = useState("");

  const [region] = useRegion();

  if (!open) {
    classes.push("gumsum");
  }

  return (
    <div className={classes.join(" ")}>
      <button onClick={() => onClose(false)} className="cart-close"></button>

      <div className="toggle-header">
        <h3 className="mbm">
          <FormattedMessage
            id="cart.item_added"
            defaultMessage="Just added to your bag"
          />
        </h3>
        <p className="pbl border-bottom">
          <span className="shipping-checked mrs">
            <img src={shippingCheck} width="15" />
          </span>
          {region === "au"
            ? "Free shipping & returns on all orders"
            : "Free shipping on all orders"}
        </p>
      </div>
      <div className="toggle-products">
        {order.products.map((p, i) => {
          return (
            <div key={i} className="single-item">
              {p.product_id === 2 ? (
                <img src={p.image} />
              ) : (
                <a href={p.url}>
                  <img src={p.image} />
                </a>
              )}
              <div className="item-detail">
                {p.product_id === 2 ? <a href="#">{p.model}</a> : <a href={p.url}>{p.model}</a>}

                {p.product_id === 2 ? null : (
                  <div>
                    <span>Size: </span>
                    {p.size.length > 0 && <p>{p.size}</p>}
                  </div>
                )}

                <div>
                  <span>Price: </span>
                  {p.product_id === 2 ? <p>Free</p> : (
                    <>
                      <p>{p.price}</p>
                      {p.gross_price !== p.price && (
                        <p className="discount-price">{p.gross_price}</p>
                      )}
                    </>
                  )}
                </div>

                <div>
                  <span>QTY</span>
                  <p>
                    {p.product_id === 2 ? (
                      p.quantity
                    ) : (
                      <>
                        <i
                          className="fa fa-minus-circle mrm"
                          data-decrement-product
                          aria-hidden="true"
                          onClick={() => onQuantityUpdate(p, "decrement")}
                        ></i>
                        {p.quantity}
                        <i
                          className="fa fa-plus-circle mlm"
                          data-add-product
                          aria-hidden="true"
                          onClick={() => onQuantityUpdate(p, "increment")}
                        ></i>
                      </>
                    )}
                  </p>
                </div>

                {p.product_id === 2 ? null : (
                  <a
                    onClick={() => onQuantityUpdate(p, "clear")}
                    className="remove"
                  >
                    Remove
                  </a>
                )}
              </div>
              <div className="clr"></div>
            </div>
          );
        })}
      </div>

      <div className="toggle-cart-total border-top ptm">
        <div className="promo">
          <input
            type="text"
            name="coupon"
            placeholder="Enter Promo Code"
            value={coupon}
            onChange={(v) => setCoupon(v.target.value)}
          />
          <button
            type="submit"
            className="cart-btn-link mbl"
            onClick={() => onAddCoupon(coupon)}
          >
            ADD
          </button>
          {couponError && <p className="red mbl">{couponError}</p>}
        </div>

        <div className="cardtotal mbl">
          {order.discount_desc && (
            <>
              <strong>Discount {order.discount_desc}</strong>
              <p>{order.discount_amount}</p>
            </>
          )}

          {order.tax > 0 && (
            <>
              <strong>Tax</strong>
              <p>{order.tax}</p>
            </>
          )}

          <strong>
            <FormattedMessage
              id="cart.total_shipping"
              defaultMessage="total_shipping"
            />
          </strong>
          <p>Free</p>

          {order.extrasDisplay && (
            <>
              {order.extrasDisplay.map((ed) => (
                <>
                  <strong>
                    <FormattedMessage id={ed[0]} defaultMessage={ed[0]} />
                  </strong>
                  <p key={ed[1]}>{ed[1]}</p>
                </>
              ))}
            </>
          )}

          <strong>
            <FormattedMessage id="cart.total" defaultMessage="Total" />
          </strong>
          <p>{order.total}</p>
        </div>

        <div className="text-center">
          <a
            type="submit"
            className="cart-btn btn btn-default pull-right mbl"
            href="/checkout"
          >
            <FormattedMessage id="checkout.heading" defaultMessage="CHECKOUT" />
          </a>
          {/*<a href="/cart?from=dropdown" className="mvl pvm">
            <FormattedMessage id="cart.view" defaultMessage="VIEW CART" />
          </a>*/}
        </div>
      </div>

      <SuggestedProducts
        suggestions={suggestions}
        onAddToCart={onAddToCart}
        id={id}
      />
    </div>
  );
};

const Cart = (props) => {
  const [order, setOrder] = useState({ products: [] });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [locale, messages] = useLocale();

  const [suggestions, setSuggestions] = useState([]);
  const [couponError, setCouponError] = useState("");

  const dropdownRef = useRef(null); // Adding a ref

  useEffect(() => {
    fetchOrder();
    // Attach the click event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Return a function to be called on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleOpenEvent = () => {
      setOpen(true);
      setLoading(true);
      fetchOrder();
      fetchSuggestions();
    };

    if (dropdownRef.current) {
      dropdownRef.current.addEventListener("openCart", handleOpenEvent);
    }

    return () => {
      if (dropdownRef.current) {
        dropdownRef.current.removeEventListener("openCart", handleOpenEvent);
      }
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (order.products.length > 0) {
      fetchSuggestions();
    }
  }, [order]);

  const fetchSuggestions = async () => {
    console.log("fetchSuggestions", order.products);
    const resp = await axios.get(
      `/product/suggested/${order.products.map((p) => p.product_id).join(",")}`
    );
    if (resp.data) {
      console.log("fetchSuggestions -> resp.data", resp.data);
      setSuggestions(resp.data);
    }
  };

  // Check if clicked on outside of element
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleUpdateQuantity = async (product, command) => {
    setLoading(true);

    let url = "";
    let payload = {};

    if (product.quantity == 1 && command == "decrement") {
      command = "clear";
    }

    if (command == "increment") {
      url = `/order/add-product`;
      payload = {
        order_product: product.opId,
      };
    } else if (command == "decrement") {
      url = `/order/remove-product`;
      payload = {
        order_product: product.opId,
      };
    } else if (command == "clear") {
      url = `/order/remove-product`;
      payload = {
        order_product: product.opId,
        completely: "true",
      };
    }

    const resp = await axios.post(url, payload);
    console.log("handleUpdateQuantity -> resp", resp, "payload", payload);
    if (resp.data.success) {
      fetchOrder();
    } else {
      showErrorMessage("Something went wrong. Please try again.");
    }

    setLoading(false);
  };

  const fetchOrder = async () => {
    const resp = await axios.get(`/order/current`);
    if (resp.data) {
      setOrder(resp.data);
    }
    setLoading(false);
  };

  const handleAddToCart = async (productId, sizeId) => {
    setLoading(true);
    let size = 103;
    if (sizeId) {
      size = sizeId;
    }
    const payload = {
      product: productId,
      size: size,
      measurements: [],
      sizeLater: false,
    };

    try {
      const resp = await axios.post(`/order/add-product`, payload);
      if (resp.data.success) {
        fetchOrder();
        fetchSuggestions();

        if (dropdownRef.current) {
          dropdownRef.current.addEventListener("openCart", handleOpenEvent);
        }
      } else {
        showErrorMessage("Something went wrong. Please try again.");
      }
    } catch (e) {
      showErrorMessage("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showCouponError = (message) => {
    setCouponError(message);
    setTimeout(() => {
      setCouponError("");
    }, 3000);
  };

  const handleAddCoupon = (coupon) => {
    setLoading(true);
    const payload = {
      coupon: coupon,
    };

    axios
      .post(`/order/validate-coupon`, payload)
      .then((resp) => {
        if (resp.data.success) {
          fetchOrder();
        } else {
          showCouponError(resp.data.message);
        }
      })
      .catch((e) => {
        if (e.response) {
          showCouponError(e.response.data.message);
        } else {
          showCouponError("Something went wrong. Please try again.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const details = () => {
    if (open && loading) {
      return <CartLoading />;
    }

    if (open && order.products.length == 0) {
      return <EmptyCart onClose={handleClose} />;
    }

    return (
      <CartWithProducts
        order={order}
        onQuantityUpdate={handleUpdateQuantity}
        suggestions={suggestions}
        onAddToCart={handleAddToCart}
        open={open}
        id={props.id}
        onClose={handleClose}
        onAddCoupon={handleAddCoupon}
        couponError={couponError}
      />
    );
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <div className="dropdown" ref={dropdownRef} id={props.id}>
        <a
          onClick={() => setOpen(!open)}
          className="added-items dropdown-toggle pull-left cart-icon"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="true"
        >
          {order.products.length > 0 && (
            <span className="incart">{order.products.length}</span>
          )}

          <img src={CartIcon} width="22" />
        </a>
        {details()}
      </div>
    </IntlProvider>
  );
};

export default Cart;
