// useAxios a wrapper around axios to make it easier to use
// adds header for CSRF token

import axios from 'axios';

const useAxios = () => {
  const token = document.querySelector('meta[name="csrf-token"]')?.content;
  axios.defaults.headers.common['X-CSRF-Token'] = token;
  return axios;
}

export default useAxios;
