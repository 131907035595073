import React, { useState,useImperativeHandle, forwardRef } from "react";
import useLocale from "../Locale";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { FormattedMessage, IntlProvider } from "react-intl";

const StripeCheckoutForm = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [locale, messages] = useLocale();

  const stripe = useStripe();
  const elements = useElements();

  const { clientSecret } = props;

  const cardStyle = {
    style: {
      base: {
        color: "#313131",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#ccc",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const cardNumberProps = {
    ...cardStyle,
    placeholder: "Card number",
    showIcon: true,
    iconStyle: "solid",
  };

  const cardCvcProps = {
    ...cardStyle,
    placeholder: "Security code (CVV)",
  };

  const expiryProps = {
    ...cardStyle,
    placeholder: "Expiration date (MM/YY)",
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async () => {

    props.setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });
    console.log(payload);
    if (payload.error && (!payload.error.payment_intent || payload.error.payment_intent.status !== 'succeeded')) {
      setError(`Payment failed ${payload.error.message}`);
      props.setProcessing(false);
    } else {
      try {
        var intentId = payload.paymentIntent ? payload.paymentIntent.id : payload.error.payment_intent.id;
        const config = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ payment_intent_id: payload.paymentIntent.id }),
        };
        const fetchResponse = await fetch(`/stripe/complete-intent`, config);
        const data = await fetchResponse.json();
        console.log(data);
        setError(null);
        props.setProcessing(false);
        if (data.success) window.location.href = "/success";
      } catch (e) {
        setError(null);
        props.setProcessing(false);
      }
    }
  };

  return (
    <IntlProvider messages={messages} locale={locale}>
    <div>
        <React.Fragment>
          <div
            tabIndex="-1"
            role="dialog"
          >
            <div>
                <div className="">

                  <form id="payment-form">
                    <div className="cardElement">
                      <CardNumberElement
                        onChange={handleChange}
                        options={cardNumberProps}
                      />
                    </div>

                    <div className="cardElement">
                      <CardExpiryElement
                        onChange={handleChange}
                        options={expiryProps}
                      />
                    </div>

                    <div className="cardElement">
                      <CardCvcElement
                        onChange={handleChange}
                        options={cardCvcProps}
                      />
                    </div>

                    {error && (
                      <div className="mtm red card-error" role="alert">
                        {error}
                      </div>
                    )}
                  </form>
                </div>

            </div>
          </div>
        </React.Fragment>
    </div>
    </IntlProvider>
  );
});

export default StripeCheckoutForm;
