import English from "../lang/en.json";
import German from "../lang/de.json";

const useLocale = () => {
    const getLocale = () => {
        if (location.host.endsWith("de")) {
            return "de";
        }
        return "en";
    }

    const locale= getLocale();
    const messages =  (locale === "de") ? German : English;

    return [locale, messages];
};

export default useLocale;
