import React, { useMemo } from "react";
import { useEffect, useCallback, useRef } from "react";
import { SizeType } from "./Types";
import useMutationObservable from "./hooks/useMutationObservable";
import { FormattedMessage, FormattedNumber } from "react-intl";

const MadeToMeasureButton = ({ mtmPrice, currency, onClick }) => {
  const getMtm = useCallback(() => {
    if (mtmPrice && mtmPrice > 0) {
      return (
        <React.Fragment>
          {" + "}
          <FormattedNumber
            value={mtmPrice}
            style="currency"
            currency={currency}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
          />
        </React.Fragment>
      );
    }
    return null;
  }, [mtmPrice, currency]);

  return (

      <button
        onClick={onClick}
        className="customize-btn made-to-measure btn btn-default mbl"
      >
        <FormattedMessage
          id="product.cart.mtm"
          defaultMessage={"MADE TO MEASURE"}
        />
        {getMtm()}
      </button>

  );
};

const SizeList = ({ sizes, selectedSize, onChange }) => {
  const options = useMemo(() => {
    return sizes.map((s) => {
      return { value: s.id, label: s.name };
    });
  }, [sizes]);

  return (
    <>
      <select
        className="pro-select mbl"
        onChange={(e) => onChange(e.target.value)}
        value={selectedSize}
      >
        <option value="0">Select a Regular Size</option>
        {options.map((s) => (
          <option key={s.value} value={s.value}>
            {s.label}
          </option>
        ))}
      </select>
    </>
  );
};

const Size = ({ size, selectedSize, onChange }) => {
  return (
    <div>
      <input
        id={"lsize-" + size.id}
        value={size.id}
        checked={selectedSize == size.id}
        onChange={() => onChange(size.id)}
        type="radio"
      />
      <label htmlFor={"lsize-" + size.id}>{size.name}</label>
    </div>
  );
};

const EviternityWidh = ({ width, selectedWidth, onChange }) => {
  return (
    <div>
      <input
        id={"lwidth-" + width.id}
        value={width.name}
        checked={selectedWidth.id == width.id}
        onChange={() => onChange(width.id, width.name)}
        type="radio"
      />
      <label htmlFor={"lwidth-" + width.id}>{width.name}</label>
    </div>
  );
};

const EviternityWidthButtons = ({ widths, selectedWidth, onChange }) => {
  return (
    <div className="prod-size prod-width">
      <div className="row pvn">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <strong>Width</strong>

          <div className="size-options-row grid3 mtm">
          {widths.map((w) => (
            <EviternityWidh
              key={w.id}
              width={w}
              selectedWidth={selectedWidth}
              onChange={onChange}
            />
          ))}
        </div>
        </div>
      </div>

    </div>
  );
};

const scrollToRef = (ref, offsetY) => {
  if (!ref.current) return;

  const top =
    ref.current.getBoundingClientRect().top + window.pageYOffset - offsetY;
  window.scrollTo({ top, behavior: "smooth" });
};

const isInViewport = (element) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const SizeError = ({ show }) => {
  const errorRef = useRef(null);
  const offsetY = 300; // Adjust this value to change the offset from the top of the screen

  useEffect(() => {
    console.log("show", show, errorRef.current);
    if (show && errorRef.current && !isInViewport(errorRef.current)) {
      scrollToRef(errorRef, offsetY);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="error text-danger mbl" id="sizeError" ref={errorRef}>
      <FormattedMessage
        id="product.cart.size-missing"
        defaultMessage={"Please select a size"}
      />
    </div>
  );
};

export default function ProductSizes({
  sizes,
  mtmPrice,
  currency,
  selectedSize,
  onChange,
  isEviternity = false,
  widths,
  selectedWidth,
  onEviternityWidthChange,
  madetomeasure,
  isAccessory = false,
  region = 'global',
  isBeta = false,
  sizetoolDisabled = false,
  hideCustomization = false,
}) {
  const [sizeGroups, setSizeGroups] = React.useState([]);
  const sizesRef = React.useRef();
  var isJacket = !(isEviternity || isAccessory);
  const isShoes = isEviternity;

  const getSizeLabel = () => {
    if (region == 'uk') {
      return 'UK';
    } else if (region == 'au') {
      return 'AU';
    } else {
      return 'US';
    }
  }

  const createSizeGroups = (sizes) => {
    return sizes.reduce((acc, size, currentIndex) => {
      var sizeGroup = 4;
      if (isEviternity) {
        sizeGroup = 8;
      } else if (isAccessory) {
        sizeGroup = 9;
      }

      const groupIndex = Math.floor(currentIndex / sizeGroup);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(size);
      return acc;
    }, []);
  };

  useEffect(() => {
    const groups = createSizeGroups(sizes);
    setSizeGroups(groups);
  }, [sizes]);

  const handleMTMClick = () => {
    if (isEviternity) {
      if (selectedSize.type == SizeType.SHOES_MADE_MEASURE) {
        onChange();
      } else {
        onChange(SizeType.SHOES_MADE_MEASURE);
      }
      return;
    }

    if (selectedSize.type == SizeType.MADE_TO_MEASURE) {
      onChange();
    } else {
      onChange(SizeType.MADE_TO_MEASURE);
    }
  };

  const onSizeMutation = useCallback(
    (mutationList) => {
      mutationList
        .map((m) => m.addedNodes)
        .forEach((node) => {
          if (node.length > 0 && node[0].id === "szb-vfr-recommendation") {
            const sizeMatch = node[0].innerHTML.match(/<b>(.*)<\/b>/);
            if (sizeMatch && sizeMatch.length == 2) {
              sizes
                .filter((s) => s.name === sizeMatch[1])
                .forEach((s) => onChange(SizeType.REGULAR, s.id));
            }
          }
        });
    },
    [sizes]
  );

  useMutationObservable(sizesRef.current, onSizeMutation);

  return (
    <div ref={sizesRef}>
      <div className="prod-size mtm">


        {!isJacket && (
          <>

          {isShoes && (<div className="row ptn">
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <strong>Size: {getSizeLabel()} Sizes</strong>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 view-size text-right">
              <a href="<%= linkt'#'%>" data-toggle="modal" data-target=".size-guide" class="size-guide-btn"><strong className="size-guide-link" data-source="SizeGuideButton">Size Guide</strong></a>
            </div>

          </div>)}

          {sizeGroups.map((sz, i) => (
          <div
            key={i}
            className="size-options-row"
          >
            {sz.map((s, j) => (
              <Size
                key={j}
                size={s}
                selectedSize={selectedSize.id}
                onChange={(sId) => onChange(SizeType.REGULAR, sId)}
              />
            ))}
          </div>
          ))}
          </>
        )}
        {isJacket &&
        <>

          {sizetoolDisabled ? (
            <div className="row ptn">
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                <strong>Size: </strong>
                <p className="span mtm mhn">(Size up for layering or if you're between sizes)</p>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3 view-size text-right">
                  <a href="<%= linkt'#'%>" data-toggle="modal" data-target=".size-guide" class="size-guide-btn"><strong className="size-guide-link" data-source="SizeGuideButton">Size Guide</strong></a>
              </div>
            </div>
          ) : (

          <div className="row ptn">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <strong>Size: </strong>
              <p className="span mtm mhn">(Size up for layering or if you're between sizes)</p>
            </div>
          </div>
          )}


          {sizeGroups.map((sz, i) => (
            <>
          <div
            key={i}
            className="size-options-row"
          >
            {sz.map((s, j) => (
              <Size
                key={j}
                size={s}
                selectedSize={selectedSize.id}
                onChange={(sId) => onChange(SizeType.REGULAR, sId)}
              />
            ))}
            </div>

              {i == 1 && !hideCustomization && (<MadeToMeasureButton
                mtmPrice={mtmPrice}
                currency={currency}
                onClick={handleMTMClick}
              />)}
              </>
          ))}
        </>
         }

        <div id="sizebay-container" />

      </div>

      {isEviternity &&
        ![SizeType.LATER, SizeType.SHOES_MADE_MEASURE].includes(
          selectedSize.type
        ) && (
          <EviternityWidthButtons
            widths={widths}
            selectedWidth={selectedWidth}
            onChange={onEviternityWidthChange}
          />
                  )}

      {isEviternity && (
        <MadeToMeasureButton
          mtmPrice={mtmPrice}
          currency={currency}
          onClick={handleMTMClick}
        />
      )}
    </div>
  );
}
