import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import VerifiedBuyerImage from "images/v2/icons/verified.png";
import { FormattedMessage, IntlProvider } from "react-intl";
import useLocale from "../Locale";

const Review = ({ review }) => {
  const ratingRef = useCallback((node) => {
    if (node != null) {
      $(node).rating();
    }
  });

  return (
    <div className="row phm borderclass <%= 'hidden' if i > 10 %>">
      <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 pbl pln prn">
        <div className="stars">
          <input
            className="rating rating-loading"
            ref={ratingRef}
            value={review.rating}
            disabled={true}
            data-min="0"
            data-max="5"
            data-step="0.25"
            data-size="xs"
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 pbl pln prn">
        <strong>{review.author}</strong>
        <div className="verified mtm">
          <img src={VerifiedBuyerImage} />Verified Buyer
        </div>
      </div>
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pbl prn">
        <p className="customerfeedback">{review.text}</p>
      </div>
      <div className="col-xs-12 col-sm-1 col-md-1 col-lg-1 pbl prn">
        <strong>{review.date}</strong>
      </div>
    </div>
  );
};

const NewProductReview = ({productId}) => {
  const PAGE_SIZE = 5;
  const [filters, setFilters] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [shownReviews, setShownReviews] = useState([]);

  const [showLoadMore, setShowLoadMore] = useState(false);

  const [locale, messages] = useLocale();
  const [activeFilters, setActiveFilters] = useState([messages["reviews.all"]]);

  const isInSelectedFilter = (ts) => {
    return activeFilters.filter((a) => ts.includes(a)).length > 0;
  };

  const updateFilteredReviews = () => {
    if (activeFilters.includes(messages["reviews.all"])) {
      setFilteredReviews([...reviews]);
    } else {
      setFilteredReviews(reviews.filter((r) => isInSelectedFilter(r.tags)));
    }
  };

  useEffect(() => {
    setShowLoadMore(filteredReviews.length > shownReviews.length);
  }, [shownReviews]);

  useEffect(() => {
    setShownReviews([
      ...filteredReviews.slice(0, Math.min(PAGE_SIZE, filteredReviews.length)),
    ]);
  }, [filteredReviews]);

  useEffect(() => {
    updateFilteredReviews();
  }, [activeFilters, reviews]);

  const fetchReviews = () => {
    fetch("/reviews/list?productId=" + productId)
      .then((response) => response.json())
      .then((data) => setReviews(data.list));
  };

  const fetchReviewTags = () => {
    fetch("/reviews/tags")
      .then((response) => response.json())
      .then((data) =>
        setFilters([messages["reviews.all"], ...new Set(data.list.map((l) => l.name))])
      );
  };

  useEffect(() => {
    fetchReviews();
    fetchReviewTags();
  }, []);

  const isActive = (f) => {
    return activeFilters.includes(f);
  };

  const loadMore = () => {
    setShownReviews([
      ...filteredReviews.slice(
        0,
        Math.min(shownReviews.length + PAGE_SIZE, filteredReviews.length)
      ),
    ]);
  };

  const handleFilterToggle = (f) => {
    if (activeFilters.includes(f)) {
      setActiveFilters([messages["reviews.all"]]);
    } else {
      setActiveFilters([f]);
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <React.Fragment>

        {shownReviews.map((r) => (
          <Review key={r.id} review={r} />
        ))}

        {shownReviews.length === 0 && (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <p><FormattedMessage id="reviews.no_review" defaultMessage={"No reviews to show"} /></p>
            </div>
          </div>
        )}

        {showLoadMore && (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <button className="btn-loadmore" onClick={loadMore}>
                <FormattedMessage defaultMessage={"Load More"} id={"reviews.load_more"} />
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    </IntlProvider>
  );
};

export default NewProductReview;
