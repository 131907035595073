import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import useLocale from "../Locale";

const Review = ({ review }) => {
  const ratingInputRef = useRef(null);
  const [showFull, setShowFull] = useState(false);

  useEffect(() => {
    if (ratingInputRef != null) {
      $(ratingInputRef.current).rating();
    }
  }, [ratingInputRef]);

  return (
    <div className="review-details mtm">
      <div className="review-head">
        <div className="name-date">
          <strong>{review.author}</strong>
        </div>
        <span>
          <input
            className="rating rating-loading"
            value={review.rating}
            ref={ratingInputRef}
            disabled={true}
            data-min="0"
            data-max="5"
            data-step="0.25"
            data-size="xs"
          />
        </span>
      </div>

      {!showFull && <p>{review.text_truncated}</p>}
      {showFull && <p>{review.text}</p>}

      {review.text_truncated.endsWith("...") && !showFull && (
        <a
          className="read-more review-read-more"
          onClick={() => setShowFull(true)}
        >
          <FormattedMessage
            id="review.read_more"
            defaultMessage={"Read More"}
          />
        </a>
      )}
    </div>
  );
};

const Pagination = ({ page, totalPages, onChange }) => {
  const getPages = () => {
    const start = Math.max(0, page - 2);
    const end = Math.min(totalPages, page + 2);
    const pages = Array.from({ length: end - start }, (_, k) => k + start + 1);
    
    if (start > 1) {
      pages.unshift("...");
    }

    if (start > 0) {
      pages.unshift(1);
    }

    if (end < totalPages - 1) {
      pages.push("...");
      pages.push(totalPages - 1);
    }
    return pages;
  };

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="review-pagging">
      <ul>
        <li>
          <a
            onClick={() => onChange(Math.max(page - 1, 0))}
            className="glyphicon glyphicon-triangle-left"
          ></a>
        </li>
        {getPages().map((i, ind) => (
          <li key={ind} className={i == page ? "active" : ""}>
            {i != "..." && <a onClick={() => onChange(i)}>{i}</a>}
            {i == "..." && <span>...</span>}
          </li>
        ))}
        <li>
          <a
            onClick={() => onChange(Math.min(page + 1, totalPages))}
            className="glyphicon glyphicon-triangle-right"
          ></a>
        </li>
      </ul>
    </div>
  );
};

const ReviewList = ({ productId }) => {
  const [locale, messages] = useLocale();

  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetch("/product/reviews?product_id=" + productId)
      .then((res) => res.json())
      .then((data) => {
        setReviews(data.reviews);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setFilteredReviews(reviews.slice((page - 1) * 3, page * 3));
  }, [page, reviews]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {filteredReviews.map((review, i) => (
        <Review key={i} review={review} />
      ))}
      <Pagination
        page={page}
        onChange={(p) => setPage(p)}
        totalPages={Math.ceil(reviews.length / 3.0)}
      />
      {reviews.length == 0 && (
        <p>
          <FormattedMessage
            id="product.reviews.no_review"
            defaultMessage={"There are no reviews for this product."}
          />
        </p>
      )}
    </IntlProvider>
  );
};

export default ReviewList;
