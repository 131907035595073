import React, { useState, useEffect } from "react";
import axios from "axios";

function OrderTotalSummary({ isBlackFriday }) {
  const [order, setOrder] = useState(null);

  const handlePaymentInfoUpdated = async () => {
    const res = await axios.get("/order/total-summary");
    setOrder(res.data);
  };

  useEffect(() => {
    document.addEventListener("payment-info-updated", handlePaymentInfoUpdated);
    handlePaymentInfoUpdated();

    return () => {
      document.removeEventListener(
        "payment-info-updated",
        handlePaymentInfoUpdated
      );
    };
  }, []);

  if (!order) {
    return <div>Loading ...</div>;
  }

  return (
    <div className="col-sm-12 col-xs-12 text-left">
      <div className="row block-padding grand-total">
        <div className="col-sm-6 col-xs-6">
          <p>Subtotal</p>
          <p>Shipping</p>
          <p>Taxes</p>
          {order.extrasDisplay.map((ed) => (
            <p key={ed[0]}>{ed[0]}</p>
          ))}
          {order.hasDiscount && (
            <p>
              {isBlackFriday
                ? `Black Friday Discount ${order.discountDesc}`
                : `Discount ${order.discountDesc}`}
            </p>
          )}
        </div>
        <div className="col-sm-6 col-xs-6 bold-values text-right">
          <p>{order.subtotal}</p>
          <p>Free</p>
          <p>{order.tax}</p>
          {order.extrasDisplay.map((ed) => (
            <p key={ed[1]}>{ed[1]}</p>
          ))}
          {order.hasDiscount && <p>{order.discount}</p>}
        </div>
      </div>
      <div className="row border-top">
        <div className="col-sm-6 col-xs-6 bold-values ptl">
          <h5>Total</h5>
        </div>
        <div className="col-sm-6 col-xs-6 bold-values ptl text-right">
          <h5>{order.total}</h5>
        </div>
      </div>
    </div>
  );
}

export default OrderTotalSummary;
