import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";

import StripeCheckoutForm from "./StripeCheckoutForm";
import useLocale from "../Locale";
import "./style.css";
import { IntlProvider } from "react-intl";

const StripePayment = forwardRef((props, ref) => {
  const [locale, messages] = useLocale();
  const checkoutFormRef = useRef();
  const [clientSecret, setClientSecret] = useState("");

  useImperativeHandle(ref, () => ({
    submit: () => checkoutFormRef.current.handleSubmit(),
  }));

  useEffect(() => {
    document.addEventListener("show-payment-details-page", () => {
      fetchPaymentIntent();
    });
  }, []);

  const fetchPaymentIntent = () => {
    // Create PaymentIntent as soon as the page loads
    window
      .fetch("/stripe/intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pap_token: "abcd" }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.client_secret);
      });
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <div className="ccbutton">
          <div className="stripe-checkout">
            <StripeCheckoutForm
              setProcessing={props.setProcessing}
              ref={checkoutFormRef}
              clientSecret={clientSecret}
            />
          </div>
      </div>
    </IntlProvider>
  );
});

export default StripePayment;
