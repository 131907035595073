import React, { useReducer, useEffect} from "react";
import HelpIcon from "images/v2/icons/how.svg";
import Collapse from "@kunukn/react-collapse";
import useShoeMeasurements from "./hooks/useShoeMeasurements";
import { SizeType } from "./Types";
import { FormattedMessage } from "react-intl";

const initialState = {
  measurementType: "inches",
  footLength: "",
  forefoot: "",
  instep: "",
  regularSizeType: "US",
  regularSize: "7.0",
  footWidth: "Standard",
  uniqueFeatures: "None",
};

const reducer = (state, action) => {
  console.log("state", state);

  switch (action.type) {
    case "measurementType":
      return { ...state, measurementType: action.payload };
    case "footLength":
      return { ...state, footLength: action.payload };
    case "forefoot":
      return { ...state, forefoot: action.payload };
    case "instep":
      return { ...state, instep: action.payload };
    case "regularSizeType":
      return { ...state, regularSizeType: action.payload.type, regularSize: action.payload.size };
    case "regularSize":
      return { ...state, regularSize: action.payload };
    case "footWidth":
      return { ...state, footWidth: action.payload };
      case "uniqueFeatures":
      return { ...state, uniqueFeatures: action.payload };
    default:
      throw new Error("Invalid action type");
  }
};

export default function ShoesMadeToMeasure({ selectedSize, onChange, error }) {
  const [regularSizeTypes, regularSizes, measurementTypes, footWidthTypes, uniqueFeaturesTypes] =
    useShoeMeasurements();

  const [measurements, dispatch] = useReducer(reducer, initialState);

  const handleSizeLater = () => {
    console.log(selectedSize);
    if (selectedSize.type == SizeType.LATER) {
      onChange();
    } else {
      onChange(SizeType.LATER);
    }
  };

  useEffect(() => {
    if (selectedSize.type == SizeType.SHOES_MADE_MEASURE) {
      onChange(SizeType.SHOES_MADE_MEASURE, null, {}, measurements);
    }
  }, [measurements]);

  return (
    <Collapse
      isOpen={
        selectedSize.type == SizeType.SHOES_MADE_MEASURE ||
        selectedSize.type == SizeType.LATER
      }
    >
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mbl">
        <div className="ev-mtm">
          <div className="measurement">
            <div className="mtm-inline mrl">
              <strong>Measurement:</strong>
            </div>
            {measurementTypes.map((m, i) => (
              <div key={i} className={i == 0 ? "mtm-inline mrl" : "mtm-inline"}>
                <input
                  className="form-check-input mrs"
                  type="radio"
                  id={m}
                  value={measurements.measurementType}
                  checked={measurements.measurementType === m}
                  onChange={(e) => {
                    dispatch({
                      type: "measurementType",
                      payload: m,
                    });
                  }}
                />
                <label className="form-check-label" htmlFor={m}>
                  {m}
                </label>
              </div>
            ))}
          </div>

          <div className="measurement mtm">
            <div className="mtm-inline mrl mts">
              <strong>Foot Length:</strong>
            </div>
            <div className="mtm-inline len-wid mrl">
              <input
                type="number"
                className="form-control length mrs"
                id="Length"
                placeholder="Foot Length"
                value={measurements.footLength}
                onChange={(e) => {
                  dispatch({
                    type: "footLength",
                    payload: e.target.value,
                  });
                }}
              />
              <a href="#" data-toggle="modal" data-target=".foot-length">
                <img width="25" className="pull-left mts" src={HelpIcon} />
              </a>
            </div>
          </div>
          <div className="measurement mtm">
            <div className="mtm-inline width mrl mts">
              <strong>Forefoot Circumference:</strong>
            </div>
            <div className="mtm-inline width len-wid">
              <input
                type="text"
                className="form-control width mrs"
                id="forefoot"
                placeholder="Forefoot Circumference"
                value={measurements.forefoot}
                onChange={(e) => {
                  dispatch({
                    type: "forefoot",
                    payload: e.target.value,
                  });
                }}
              />
              <a href="#" data-toggle="modal" data-target=".forefoot">
                <img width="25" className="pull-left mts" src={HelpIcon} />
              </a>
            </div>
          </div>
          <div className="measurement mtm">
            <div className="mtm-inline width mrl mts">
              <strong>Instep Circumference:</strong>
            </div>
            <div className="mtm-inline width len-wid">
              <input
                type="text"
                className="form-control width mrs"
                id="instep"
                placeholder="Instep Circumference"
                value={measurements.instep}
                onChange={(e) => {
                  dispatch({
                    type: "instep",
                    payload: e.target.value,
                  });
                }}
              />
              <a href="#" data-toggle="modal" data-target=".instep">
                <img width="25" className="pull-left mts" src={HelpIcon} />
              </a>
            </div>
          </div>
          <div className="measurement mtm">
            <div className="mtm-inline mrl mts">
              <strong>Your Regular Size:</strong>
            </div>
            <div className="mtm-inline regular len-wid mrm">
              <select
                className="form-select mrm"
                aria-label="Default select example"
                onChange={(e) => {
                  dispatch({
                    type: "regularSizeType",
                    payload: {
                      type: e.target.value,
                      size: regularSizes[e.target.value][0],
                    }
                  });
                }}
                value={measurements.regularSizeType}
              >
                {regularSizeTypes.map((m, i) => (
                  <option key={i} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            
              <select
                className="form-select mrm"
                aria-label="Default select example"
                value={measurements.regularSize}
                onChange={(e) => {
                  dispatch({
                    type: "regularSize",
                    payload: e.target.value,
                  });
                }}
              >
                {regularSizes[measurements.regularSizeType].map((m, i) => (
                  <option key={i} value={m}>
                    {m}
                  </option>
                ))}
              </select>

              <select
                className="form-select"
                aria-label="Default select example"
                value={measurements.footWidth}
                onChange={(e) => {
                  dispatch({
                    type: "footWidth",
                    payload: e.target.value,
                  });
                }}
              >
                {footWidthTypes.map((m, i) => (
                  <option key={i} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="measurement mtm">
            <div className="mtm-inline mrl mts">
              <strong>Unique Features:</strong>
            </div>
            <div className="mtm-inline len-wid mrl">
              <select
                className="form-select"
                aria-label="Default select example"
                value={measurements.uniqueFeatures}
                onChange={(e) => {
                  dispatch({
                    type: "uniqueFeatures",
                    payload: e.target.value,
                  });
                }}
              >
                {uniqueFeaturesTypes.map((m, i) => (
                  <option key={i} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <a href="#" data-toggle="modal" data-target=".unique">
              <img width="25" className="pull-left mts" src={HelpIcon} />
            </a>
          </div>

          <form  id="shoes-madetomeasure-form" className="form-inline mts">
            <div className="checkbox">
              <label className="mtm mrs">
                <input
                  type="checkbox"
                  name="size-later"
                  checked={selectedSize.type == SizeType.LATER}
                  onChange={handleSizeLater}
                />
              </label>
              <p>
                <FormattedMessage
                  defaultMessage="I am not sure, I'll provide measurements later on email"
                  id="product.cart.size-later"
                />
              </p>
            </div>
          </form>
        </div>
      </div>
    </Collapse>
  );
}
