const useRegion = () => {
    const getRegion = () => {
        if (location.host.endsWith("de")) {
            return "de";
        }
        else if (location.host.endsWith("au")) {
            return "au";
        }
        else if (location.host.endsWith("uk")) {
            return "uk";
        }
        return "global";
    }

    const region = getRegion();

    return [region];
}

export default useRegion;
