import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import useLocale from "../Locale";

const BillingInformation = ({ page = "" }) => {
  const [order, setOrder] = useState({});
  const [locale, messages] = useLocale();

  const fetchOrderDetails = async () => {
    const res = await axios.get("/order/billing-summary");
    setOrder(res.data);
  };

  const handleShowPaymentPage = () => {
    fetchOrderDetails();
  };

  useEffect(() => {
    document.addEventListener(
      "show-payment-details-page",
      handleShowPaymentPage
    );
    return () => {
      document.removeEventListener(
        "show-payment-details-page",
        handleShowPaymentPage
      );
    };
  }, [fetchOrderDetails]);

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div className="mbl billing-information">
        <div className="payment-block">
          <div className="payment-heading">
            <strong>
              <FormattedMessage
                id="billing.personal_details"
                defaultMessage="Personal details"
              />
            </strong>
          </div>
          {page != "amazon-pay-review" && (
            <div className="payment-edit">
              <a id="email-edit-btn">
                <FormattedMessage id="billing.edit" defaultMessage="Edit" />
              </a>
            </div>
          )}
        </div>

        <div className="payment-block">
          <div className="payment-label">
            <FormattedMessage
              id="billing.fullname"
              defaultMessage={"Full Name"}
            />
            :
          </div>
          <div className="payment-content">{order.name}</div>
        </div>

        <div className="payment-block payment-block-border">
          <div className="payment-label">
            <FormattedMessage id="billing.email" defaultMessage={"Email"} />:
          </div>
          <div className="payment-content">{order.email}</div>
        </div>

        <div className="payment-block payment-block-border">
          <div className="payment-label">
            <FormattedMessage id="billing.ship_to" defaultMessage={"Ship to"} />
            :
          </div>
          <div className="payment-content">{order.shipping_address}</div>
        </div>
        <div className="payment-block payment-block-border">
          <div className="payment-label">
            <FormattedMessage id="billing.bill_to" defaultMessage={"Bill to"} />
            :
          </div>
          <div className="payment-content">
            {order.is_billing_address_same
              ? order.shipping_address
              : order.payment_address}
          </div>
        </div>
        <div className="payment-block payment-block-border">
          <div className="payment-label">
            <FormattedMessage
              id="billing.shipping"
              defaultMessage={"Shipping"}
            />
            :
          </div>
          <div className="payment-content">
            <FormattedMessage
              id="billing.free_shipping"
              defaultMessage={"Free Shipping"}
            />
          </div>
        </div>
        <div className="payment-block payment-block-border">
          <div className="payment-label">
            <FormattedMessage
              id="billing.return_policy"
              defaultMessage={"Return Policy"}
            />
            :
          </div>
          <div className="payment-content">
            <FormattedMessage
              id="billing.days_return"
              defaultMessage={"30 Days Return"}
            />
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

export default BillingInformation;
