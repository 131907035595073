import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import CustomProductImage from "images/v2/custom-product.jpg";
import VerifiedBuyerImage from "images/v2/icons/verified.png";
import { FormattedMessage, IntlProvider } from "react-intl";
import useLocale from "../Locale";

const ReviewFilter = ({ title, active, onToggle }) => {
  const defaultClasses = ["btn", "btn-primary"];
  const [classes, setClasses] = useState(defaultClasses);

  useEffect(() => {
    if (active) {
      setClasses([...defaultClasses, "active"]);
    } else {
      setClasses([...defaultClasses]);
    }
  }, [active]);

  return (
    <label className={classes.join(" ")} onClick={() => onToggle(title)}>
      <input
        type="checkbox"
        autoComplete="off"
        checked={active}
        onChange={(e) => console.log(e)}
      />{" "}
      {title}
    </label>
  );
};

const Review = ({ review }) => {
  const ratingRef = useCallback((node) => {
    if (node != null) {
      $(node).rating();
    }
  });

  return (
    <div className="row phm borderclass <%= 'hidden' if i > 10 %>">
      <div className="col-xs-12 col-sm-1 col-md-1 col-sm-offset-1 pbl">
        <div className="stars">
          <input
            className="rating rating-loading"
            ref={ratingRef}
            value={review.rating}
            disabled={true}
            data-min="0"
            data-max="5"
            data-step="0.25"
            data-size="xs"
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-1 col-md-1 pbl">
        <strong>{review.author}</strong>
        <div className="verified mtm">
          <img src={VerifiedBuyerImage} />
          Verified Buyer
        </div>
      </div>
      <div className="col-xs-12 col-sm-1 col-md-1 pbl">
        <strong>{review.date}</strong>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 pbl">
        <p className="customerfeedback">{review.text}</p>
      </div>

      <div className="col-xs-12 col-sm-1 col-md-1">
        {review.is_enabled && (
          <a href={review.product_url}>
            <img src={review.thumbnail} width={70} />
          </a>
        )}
        {!review.is_enabled && <img src={CustomProductImage} width={70} />}
      </div>
    </div>
  );
};

const ReviewList = () => {
  const PAGE_SIZE = 20;

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [shownReviews, setShownReviews] = useState([]);

  const [showLoadMore, setShowLoadMore] = useState(false);

  const [locale, messages] = useLocale();
  const [activeFilters, setActiveFilters] = useState([messages["reviews.all"]]);

  const isInSelectedFilter = (ts) => {
    return activeFilters.filter((a) => ts.includes(a)).length > 0;
  };

  const updateFilteredReviews = () => {
    if (activeFilters.includes(messages["reviews.all"])) {
      setFilteredReviews([...reviews]);
    } else {
      setFilteredReviews(reviews.filter((r) => isInSelectedFilter(r.tags)));
    }
  };

  useEffect(() => {
    setShowLoadMore(filteredReviews.length > shownReviews.length);
  }, [shownReviews]);

  useEffect(() => {
    setShownReviews([
      ...filteredReviews.slice(0, Math.min(PAGE_SIZE, filteredReviews.length)),
    ]);
  }, [filteredReviews]);

  useEffect(() => {
    updateFilteredReviews();
  }, [activeFilters, reviews]);

  const fetchReviews = () => {
    fetch("/reviews/list")
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.list);
        setIsLoading(false);
      });
  };

  const fetchReviewTags = () => {
    fetch("/reviews/tags")
      .then((response) => response.json())
      .then((data) =>
        setFilters([
          messages["reviews.all"],
          ...new Set(data.list.map((l) => l.name)),
        ])
      );
  };

  useEffect(() => {
    fetchReviews();
    fetchReviewTags();
  }, []);

  const isActive = (f) => {
    return activeFilters.includes(f);
  };

  const loadMore = () => {
    setShownReviews([
      ...filteredReviews.slice(
        0,
        Math.min(shownReviews.length + PAGE_SIZE, filteredReviews.length)
      ),
    ]);
  };

  const handleFilterToggle = (f) => {
    if (activeFilters.includes(f)) {
      setActiveFilters([messages["reviews.all"]]);
    } else {
      setActiveFilters([f]);
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <React.Fragment>
        <div className="row borderclass pbh mvl review-multiselect">
          <div className="col-lg-11 col-md-11 col-sm-11 col-xs-12 col-sm-offset-1 ">
            <div className="btn-group" data-toggle="buttons">
              {filters.map((f) => (
                <ReviewFilter
                  key={f}
                  title={f}
                  active={isActive(f)}
                  onToggle={handleFilterToggle}
                />
              ))}
            </div>
          </div>
        </div>

        {shownReviews.map((r) => (
          <Review key={r.id} review={r} />
        ))}

        {shownReviews.length === 0 && !isLoading && (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <p>
                <FormattedMessage
                  id="reviews.no_review"
                  defaultMessage={"No reviews to show"}
                />
              </p>
            </div>
          </div>
        )}

        {isLoading && (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <p>
                <FormattedMessage
                  id="reviews.loading"
                  defaultMessage={"Loading ..."}
                />
              </p>
            </div>
          </div>
        )}

        {showLoadMore && (
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 text-center">
              <button className="btn-loadmore" onClick={loadMore}>
                <FormattedMessage
                  defaultMessage={"Load More"}
                  id={"reviews.load_more"}
                />
              </button>
            </div>
          </div>
        )}
      </React.Fragment>
    </IntlProvider>
  );
};

export default ReviewList;
