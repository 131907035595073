import React, { useState, useEffect } from "react";

export default function CouponError() {
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCouponError();
  }, []);

  const fetchCouponError = () => {
    fetch("/order/coupon-error")
      .then((res) => res.json())
      .then((data) => {
        setError(data.error);
      });
  };

  useEffect(() => {
    document.addEventListener(
      "show-payment-details-page",
      handleShowPaymentPage
    );
    return () => {
      document.removeEventListener(
        "show-payment-details-page",
        handleShowPaymentPage
      );
    };
  }, []);

  const handleShowPaymentPage = () => {
    fetchCouponError();
  };

  if (!error) {
    return null;
  }

  return (
    <div className="row">
      <div className="alert alert-danger checkout-error-alert text-center">
        <span>{error}</span>
      </div>
    </div>
  );
}
